import { navigate, Link } from "gatsby";
import React, { useState } from "react";
import {
  RealmAppProvider,
  useRealmApp,
  FACILITATOR_APP_ID,
} from "../../../components/realm_app";
import { Button } from "react-bootstrap";
import { Footer } from "../../../components/footer";
import logo from "../../../assets/MentorAmp_Logo.png";
import OnboardingNavbar from "../../../components/onboarding_navbar";
import Candy from "../../../components/candy";

import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";

const CongratsPage = () => {
  const [circleName, setCircleName] = useState("");
  const [loading, setLoading] = useState("");
  const realmApp = useRealmApp();

  async function get_circle() {
    const circle = await realmApp.db
      .collection("Circle")
      .findOne(
        { owner_email: realmApp.app.currentUser?.customData.email },
        { projection: { circle_name: 1 } }
      );
    setCircleName(circle["circle_name"]);
  }

  React.useEffect(() => {
    if (realmApp.app.currentUser) {
      get_circle();
    }
  }, []);

  return (
    <>
      {/* Added the Navbar code to display the MentorAmp logo
      and link to the facilitaor dashboard. Lidia Delgado - November 2021  */}
      <div className="page">
        <OnboardingNavbar link="/facilitator/login" />
        <div className="page-content-center">
          <h3>Congrats!</h3>
        </div>
        <div className="page-content-center">
          <h3 style={{ fontSize: "100px" }}>&#127881;</h3>
        </div>
        <div className="page-content-center">You set up your circle,</div>
        <div className="page-content-center">
          <span style={{ fontWeight: "bold" }}>{circleName}</span>
        </div>
        <br />
        <br />
        <div className="page-content-center">
          <Button
            className="light-blue-button text-center"
            onClick={() => {
              navigate("/facilitator/dashboard/");
            }}
          >
            Go to Dashboard
          </Button>
        </div>
      </div>
      <Footer /> {/* Added footer to the page. LD, November 2021 */}
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={FACILITATOR_APP_ID}>
      {/* <OnboardingRedirector location={location}> */}
      <CongratsPage />
      {/* </OnboardingRedirector> */}
    </RealmAppProvider>
  );
};
